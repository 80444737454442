<template>
  <v-app id="app" class="bg-screen">
    <router-view></router-view>
    <script v-html="jsonld" type="application/ld+json"></script>
  </v-app>
</template>
<style lang="scss" scoped>
.bg-screen {
  background-color: #85b09a;
}
</style>
<script>
// @ is an alias to /src
export default {
  name: "App",
  data: () => ({
    jsonld: {
      "@context": "https://westendseeds.com",
      "@type": "WestendSeeds",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Details",
        "item": "https://westendseeds.com/crop/details"
      }]
    }
  }),
};
</script>
