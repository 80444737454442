import { render, staticRenderFns } from "./SpecifiedCrops.vue?vue&type=template&id=0b62c434&scoped=true"
import script from "./SpecifiedCrops.vue?vue&type=script&lang=js"
export * from "./SpecifiedCrops.vue?vue&type=script&lang=js"
import style0 from "./SpecifiedCrops.vue?vue&type=style&index=0&id=0b62c434&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b62c434",
  null
  
)

export default component.exports