<template>
  <div class="text-primary wrapper">
    <div class="list-cont">
      <div class="item" v-for="(crop, i) in keyArr" :key="i">
        <div class="item-cont-mobile slide-right">
          <div class="img-wrap">
          <img class="crop-img" :src="getImg(crop?.img)" />
          <div class="hover-text">
            <div class="crop-text">{{crop.note}}</div>
          <v-btn
            style="background: #006064; margin-top: 1rem; color: #fff"
            @click="onClickCrop(crop)"
          >
            See More
          </v-btn>

          </div>
          </div>
          <div class="text">{{ crop.text }}</div>
        </div>
        <div class="item-cont-pc">
          <div class="slide-right">
          <img class="crop-img" :src="getImg(crop?.img)" />
          <div class="text">{{ crop.text }}</div>
          </div>
          <div class="crop-note-pc slide-left">
            <div style="font-size: 1.5rem; color: #333">{{crop.note}}</div>
            <v-btn
              class="knowmore-pc"
              id="seemorePc"
              style="background: #006064; color: #fff"
              @click="onClickCrop(crop)"
            >
              Click Here
            </v-btn>
          </div>

        </div>
      </div>
    </div>
    <div class="img-cont d-none d-md-block"></div>
    <v-snackbar v-model="showMsg" :top="true">
      {{ "CROPS WILL DISPLAY SOON" }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showMsg = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
@media screen and (min-width: 921px) {
  /* .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    grid-gap: 1rem;
  } */

  .crop-img {
    height: 320px;
  }
  .item-cont-mobile {
    display: none;
  }
  .item-cont-pc {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 2rem;
  }
}
@media screen and (max-width: 920px) {
  .crop-img {
    height: 270px;
  }
  .item-cont-mobile {
    display: block;
  }
  .item-cont-pc {
    display: none;
  }
}
  .crop-text {
    font-size: 19px;
  }
  .img-wrap {
    position: relative;
    padding: 0;
  }
  .img-wrap .hover-text {
    position: absolute;
    opacity: 0;
    z-index: 2;
    transition: 0.3s ease-out;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    height: 100%;
  }
  .img-wrap .hover-text {
    margin-bottom: 50px;
  }

  .img-wrap .hover-text {
    padding: 10px;
    color: #fff;
  }

  .img-wrap:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(49, 50, 51, 0.623);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
    height: 98%;
    width: 100%;
    content: "";
  }

  .img-wrap:hover:after {
    visibility: visible;
    opacity: 1;
  }
  .img-wrap:hover .hover-text {
    opacity: 1;
  }
.wrapper {
  margin-top: 2rem;
}
.item {
  margin: 1rem;
  display: flex;
  justify-content: center;
}
.crop-img {
  border-radius: 5px;
  border: 1px solid #333;
  cursor: pointer;
  position: relative;
}
.text {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.text-read {
  color: #0000ee;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: -5px;
}
.crop-note-pc {
  margin: 5rem;
  width: 50%;
}
.knowmore-pc {
  margin-top: 2rem;
}
.knowmore-pc:hover {
  border: 1px solid #333;
  padding: 1.5rem;
  border-radius: 50%;
}
.knowmore-pc:hover::after {
 content: ", To know more about this crop";
 box-shadow: none;
}
.slide-right {
  animation: 1s slide-right;
  margin-bottom: 2rem;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.slide-left {
  animation: 1s slide-left;
  margin-bottom: 2rem;
}
@keyframes slide-left {
  from {
    margin-right: -100%;
  }
  to {
    margin-left: 5rem;
  }
}
</style>
<script>
import { crops } from "../utils/crop-data";
import { keys } from "../utils/crop-data";
import router from "@/router";
export default {
  name: "HomePage",
  data: () => ({
    cropObj: {},
    keyArr: [],
    selectedCrop: "",
    showMsg: false,
     
  }),
  methods: {
    onClickCrop(val) {
      router.push({ name: "specific-crops", query: { crop: val.key } });
    },
    getImg(val) {
      return require(`@/assets/crops-imgs/${val}.png`);
    },
  },
  created() {
    this.cropObj = JSON.parse(JSON.stringify(crops));
    this.keyArr = keys;
  },
};
</script>
