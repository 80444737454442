import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from 'vuetify'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: "#006064",
        previous: "#a52a2a",
        drawerText: "#E0F7FA",
        background: "#85b09a",
      },
    },
  },
});
