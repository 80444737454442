export const crops = {
  F1HybridBetterGourd: [
    {
      id: 0,
      name: "Tinkle",
      img: "tinkle",
      shape: "Thick Spindle",
      weight: "50-60 gm",
      color: "Dark Green",
      mDays: "50-55",
      dia: "3.0-3.5 cm",
      len: "10-12 cm",
      vigor: "Strong",
      varities: ["Attractive Fruits", "Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 1,
      name: "Harihar",
      img: "harihar",
      shape: "Spindle",
      weight: "50-60 gm",
      color: "Attractive Dark Green",
      mDays: "45-50",
      dia: "3.8-4.5 cm",
      len: "15-16 cm",
      vigor: "Strong",
      varities: ["Attractive Fruits", "Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 2,
      name: "Raja",
      img: "raja",
      shape: "Long Spindle & Shiny",
      weight: "120-150 gm",
      color: "Dark Green",
      mDays: "50-55",
      dia: "",
      len: "10-12 cm",
      vigor: "Strong",
      varities: [
        "Suitable For Long Distance",
        "Good Keeping Quality",
        "Excellent Yielder",
      ],
    },
    {
      id: 3,
      name: "Cutties",
      img: "cutties",
      shape: "Thick Spindle",
      weight: "50-60 gm",
      color: "Dark Green",
      mDays: "50-55",
      dia: "3.0-3.5 cm",
      len: "10-12 cm",
      vigor: "Strong",
      varities: ["Attractive Fruits", "Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 4,
      name: "Moon",
      img: "moon",
      shape: "Thick Spindle",
      weight: "140-150 gm",
      color: "Dark Green",
      mDays: "45",
      dia: "3.8-4.2 cm",
      len: "19-20 cm",
      vigor: "Excellent Vine Vigore",
      varities: ["Attractive Fruits", "Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 5,
      name: "Surbhi",
      img: "surbhi",
      shape: "Spindle",
      weight: "50-60 gm",
      color: "Dark Green",
      mDays: "39-40",
      dia: "3-3.5 cm",
      len: "10-12 cm",
      vigor: "Excellent Vine Vigore",
      varities: ["Attractive Fruits", "Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 6,
      name: "WS-77",
      img: "ws77",
      shape: "Long Spindle",
      weight: "130-150 gm",
      color: "Dark Green",
      mDays: "39-40",
      dia: "4-4.5 cm",
      len: "20-25 cm",
      vigor: "Excellent Vine Vigore",
      varities: ["Very Early Hybrid", "Good Keeping Quality"],
    },
    {
      id: 7,
      name: "Golden",
      img: "golden",
      shape: "Thick Spindle",
      weight: "50-60 gm",
      color: "Dark Green",
      mDays: "50-55",
      dia: "",
      len: "16-20 cm",
      vigor: "Excellent Vine Vigore",
      varities: ["Suitable For Long Distance"],
    },
  ],
  F1HybridPumpkin: [
    {
      id: 0,
      name: "Virat",
      img: "virat",
      shape: "Flat Round",
      weight: "2.5-3 Kg",
      color: "Dark Green",
      mDays: "",
      dia: "",
      len: "",
      vigor: "Strong",
      varities: ["High yield potential"],
    },
    {
      id: 1,
      name: "Neelam",
      img: "neelam",
      shape: "Round Globe",
      weight: "50-60 gm",
      color: "Orange Yellow",
      mDays: "95-105",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Vigorous Plant",
        "Tolerant to virus & Diseases",
        "High Yielded",
        "Long Shelf Life",
      ],
    },
    {
      id: 2,
      name: "Rajan",
      img: "rajan",
      shape: "Long Spindle & Shiny",
      weight: "8-12 Kg",
      color: "Uniform Dark Green & Light Strip",
      mDays: "90-100",
      dia: "",
      len: "",
      vigor: "Strong",
      varities: [
        "Suitable For Long Distance",
        "Suitable for exports",
        "High yield variety",
      ],
    },
    {
      id: 3,
      name: "Taj",
      img: "taj",
      shape: "Flat round",
      weight: "3-4 Kg",
      color: "Green with White dots",
      mDays: "85-90",
      dia: "",
      len: "",
      vigor: "Strong",
      varities: ["Excellent fruit set"],
    },
    {
      id: 4,
      name: "WS 001",
      img: "ws001",
      shape: "Oblong to Long",
      weight: "7-8 Kg",
      color: "Orange Yellow",
      mDays: "100-110",
      dia: "",
      len: "",
      vigor: "strong",
      varities: ["Vey Uniform", "Excellent Shelf Life"],
    },
  ],
  F1HybridCucumber: [
    {
      id: 0,
      name: "Kavya",
      img: "kavya",
      shape: "Cylindrical",
      weight: "100-150 gm",
      color: "Mottled Green",
      mDays: "38-42",
      dia: "",
      len: "18-20 cm",
      vigor: "",
      varities: ["Vey Uniform"],
    },
    {
      id: 1,
      name: "Lovely",
      img: "lovely",
      shape: "Cylindrical",
      weight: "160-200 gm",
      color: "Slight Dark Green Shoulder",
      mDays: "40-50",
      dia: "",
      len: "18-22 cm",
      vigor: "",
      varities: ["Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 2,
      name: "WS 002",
      img: "ws002",
      shape: "Cylindrical, Taper Slightly at both sides",
      weight: "120-150 gm",
      color: "Dark Green Fruits",
      mDays: "50-55",
      dia: "",
      len: "23-25 cm",
      vigor: "",
      varities: ["Short Beith", "ALpha type hybrid produces"],
    },
    {
      id: 3,
      name: "Neelam",
      img: "neelamC",
      shape: "Cylindrical",
      weight: "200-250 gm",
      color: "Dark Green",
      mDays: "40-45",
      dia: "3.0-3.5 cm",
      len: "18-20 cm",
      vigor: "",
      varities: ["Good yield", "Uniform shiny fruits"],
    },
    {
      id: 4,
      name: "Mahira",
      img: "mahira",
      shape: "Cylindrical",
      weight: "140-150 gm",
      color: "Green",
      mDays: "40-42",
      dia: "",
      len: "17-19 cm",
      vigor: "Excellent Vine Vigore",
      varities: ["Good yield", "Uniform shiny fruits"],
    },
    {
      id: 5,
      name: "Sania",
      img: "sania",
      shape: "Cylindrical",
      weight: "150-180 gm",
      color: "Attractive Green",
      mDays: "40-45",
      dia: "",
      len: "18-22 cm",
      vigor: "",
      varities: ["Attractive Fruits", "Vey Uniform", "Excellent Shelf Life"],
    },
    {
      id: 6,
      name: "Chaya",
      img: "chaya",
      shape: "Cylindrical",
      weight: "150-180 gm",
      color: "Attractive Green",
      mDays: "40-45",
      dia: "",
      len: "18-22 cm",
      vigor: "",
      varities: ["High yielding hybrid"],
    },
  ],
  F1HybridWatermelon: [
    {
      id: 0,
      name: "Marshal",
      img: "marshal",
      shape: "Capsule Oblong",
      tss: "13%",
      fruitFlesh: "Granular Crispy",
      weight: "4-6 Kg",
      color: "Greenish Black",
      mDays: "75-80",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Strong Disease Resistance",
        "High yeild",
        "Good for long transportation",
      ],
    },
    {
      id: 1,
      name: "Max Tone",
      img: "maxTone",
      shape: "Capsule Oblong",
      tss: "13%",
      fruitFlesh: "Granular Crispy",
      weight: "4-5 Kg",
      color: "Zade Black",
      mDays: "60-70",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Strong Disease Resistance",
        "High yeild",
        "Good for long transportation",
      ],
    },
    {
      id: 2,
      name: "Sugar Baby",
      img: "sugarBaby",
      shape: "Globe",
      tss: "12%",
      fruitFlesh: "Dark red with granular texture",
      weight: "6-8 Kg",
      color: "Zade Black",
      mDays: "75-80",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Strong Disease Resistance", "High yeild", "Good Shipper"],
    },
    {
      id: 3,
      name: "Abhay",
      img: "abhay",
      shape: "Oblong in shape",
      tss: "11-12%",
      weight: "9-12 Kg",
      color: "Crimson Red",
      mDays: "80-85",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Good Shipper"],
    },
    {
      id: 4,
      name: "Drona",
      img: "drona",
      shape: "Oblong",
      weight: "3-5 Kg",
      color: "Dark Green",
      tss: "12-13%",
      mDays: "62-65",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Heavy yeilder", "Fruits with excellent transport"],
    },
    {
      id: 5,
      name: "Sparkle Honey",
      img: "sparkleHoney",
      shape: "Oblong",
      weight: "50-60 gm",
      fruitFlesh: "Bright red crispy flesh",
      tss: "12-13%",
      color: "Black and Attractive Rind",
      mDays: "75-78",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Excellent Shelf Life"],
    },
    {
      id: 6,
      name: "Sweet Honey",
      img: "sweetHoney",
      shape: "Oblong",
      tss: "11.5%",
      weight: "3.5-4 Kg",
      color: "Dark Green",
      mDays: "65-70",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Good yeild potential",
        "Prolific fruit set",
        "High sweetness fruit",
        "Long distance transportation",
      ],
    },
    {
      id: 7,
      name: "Sweet Long",
      img: "sweetLong",
      shape: "Oblong",
      weight: "3-4 Kg",
      tss: "12-13%",
      color: "Bluish Green",
      mDays: "62-65",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Hybrid fruit with good yeild"],
    },
  ],
  F1HybridTomato: [
    {
      id: 0,
      name: "Yash",
      img: "yash",
      shape: "Flat Round",
      weight: "80-100 gm",
      color: "Red",
      mDays: "60-65",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Excellent Shelf life", "Disease tolerance, TLVC & Heat"],
    },
    {
      id: 1,
      name: "Salaar",
      img: "salaar",
      shape: "Flat Round",
      weight: "90-105 gm",
      color: "Attractive Red",
      mDays: "65-75",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Good Stay Greenness",
        "Dark Green Foliage",
        "Good Hear Set",
        "High Yield Potential",
      ],
    },
    {
      id: 2,
      name: "Savera",
      img: "savera",
      shape: "Square Round",
      weight: "80-90 gm",
      color: "Red",
      mDays: "55-60",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Suitable For Tropical Climate",
        "High Vigorous",
        "TY-1,2 & 3",
      ],
    },
    {
      id: 3,
      name: "Reyan",
      img: "reyan",
      shape: "Oblong",
      weight: "80-100 gm",
      color: "Dark Green",
      mDays: "60-65",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Good Disease Resistance",
        "Excellent Fruit Setting Ability",
        "Tolerance To Hear & Tolcv",
      ],
    },
    {
      id: 4,
      name: "Atush",
      img: "atush",
      shape: "Square Round",
      weight: "80-100 gm",
      color: "Deep Red",
      mDays: "50-55",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Suitable For Long Distance"],
    },
    {
      id: 5,
      name: "Hayyan-440",
      img: "hayyan440",
      shape: "Flat Round",
      weight: "90-100 gm",
      color: "",
      mDays: "50-55",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Resistance for Leaf Curl Virus (ToLCV)"],
    },
    {
      id: 6,
      name: "Sikandar",
      img: "sikandar",
      shape: "Round",
      weight: "80-100 gm",
      color: "Deep Red",
      mDays: "55-60",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Higher Yield Potential",
        "Attractive Firm Fruits",
        "Good Heat Set",
      ],
    },
    {
      id: 7,
      name: "Reyan",
      img: "reyan",
      shape: "Flattish Round",
      weight: "65-75 gm",
      color: "Deep Red",
      mDays: "55-65",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Suitable For Long Distance"],
    },
    {
      id: 8,
      name: "Yuvraj",
      img: "yuvraj",
      shape: "Oval Shaped",
      weight: "90-100 gm",
      color: "Deep Red",
      mDays: "50-55",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Suitable For Long Distance", "TLCV", "Very Firm Fruit"],
    },
  ],
  F1HybridCoriender: [
    {
      id: 0,
      name: "Super-35",
      img: "super35",
      shape: "",
      weight: "",
      color: "Dark Green",
      type: "Multi-Cut",
      mDays: "35",
      dia: "",
      len: "",
      vigor: "",
      varities: [""],
    },
    {
      id: 1,
      name: "Sukunn",
      img: "sukunn",
      shape: "",
      weight: "",
      color: "Green",
      type: "Multi-Cut",
      mDays: "42-45",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Multicut with 2-3 cuts per crop",
        "Very Good Aroma and Shelf Life",
      ],
    },
  ],
  F1HybridTinda: [
    {
      id: 0,
      name: "Navin",
      img: "navin",
      shape: "Round",
      weight: "4-45 gm",
      color: "Shiny Green, Smooth and Uniform fruit",
      mDays: "45-50",
      dia: "",
      len: "45-48 cm",
      vigor: "",
      varities: [
        "White color flush with good taste",
        "Strong Plants"
      ],
    },
  ],
  F1HybridRidgegourd: [
    {
      id: 0,
      name: "Yash",
      img: "yashR",
      shape: "Long Cylindrical",
      weight: "125-150 gm",
      color: "Shiny Green",
      mDays: "40-45",
      dia: "",
      len: "45-48 cm",
      vigor: "",
      varities: [
        "Tolerance To Dm & Heat",
        "Vigorous Vines",
        "High Branching With Wider Adaptability",
      ],
    },
    {
      id: 1,
      name: "Prince",
      img: "prince",
      shape: "Long Cylindrical with Prominent Ridges",
      weight: "125-150 gm",
      color: "Shiny Green",
      mDays: "40-45",
      dia: "",
      len: "45-48 cm",
      vigor: "",
      varities: [
        "Tolerance To Dm & Heat",
        "Vigorous Vines",
        "High Branching With Wider Adaptability",
      ],
    },
    {
      id: 2,
      name: "Early Satar",
      img: "earlySatar",
      shape: "Almost Cylindrical",
      weight: "200-250 gm",
      color: "Green",
      mDays: "40-45",
      dia: "",
      len: "30-40 cm",
      vigor: "",
      varities: ["Vigorous Vines", "High Branching With Wider Adaptability"],
    },
  ],
  F1HybridBottlegourd: [
    {
      id: 0,
      name: "Sharad",
      img: "sharad",
      shape: "Long Cylindrical",
      weight: "700-800 gm",
      color: "Shiny Green",
      mDays: "55-60",
      dia: "",
      len: "45-50 cm",
      vigor: "",
      varities: ["Tender Flesh", "High Yeilding", "Soft Seeded"],
    },
    {
      id: 1,
      name: "Savera",
      img: "saveraB",
      shape: "Long and Cylindrical",
      weight: "650-700 gm",
      color: "Attractive Dark Green",
      mDays: "50-55",
      dia: "",
      len: "40-45 cm",
      vigor: "",
      varities: ["Unifrom Size Fruit", "High Yeilding"],
    },
    {
      id: 2,
      name: "Sanu",
      img: "sanu",
      shape: "Lattu Shape",
      weight: "1-1.5 Kg (0.7 Kg/Acre)",
      color: "Dark Green with spots",
      mDays: "55-60",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Excellent Quality and Good Taste", "High Yeilding"],
    },
    {
      id: 3,
      name: "Siddhi",
      img: "siddhi",
      shape: "Oval Shaped",
      weight: "1.5-2 Kg",
      color: "Ligh Green",
      mDays: "55",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Excellent Quality and Good Taste", "High Yeilding"],
    },
  ],
  F1HybridChilli: [
    {
      id: 0,
      name: "Yash",
      img: "yashC",
      shape: "Long Cylindrical",
      weight: "125-150 gm",
      color: "Shiny Green",
      mDays: "40-45",
      dia: "",
      len: "45-48 cm",
      vigor: "",
      varities: [
        "Tolerance To Dm & Heat",
        "Vigorous Vines",
        "High Branching With Wider Adaptability",
      ],
    },
    {
      id: 1,
      name: "Uri",
      img: "uri",
      shape: "Cylindrical",
      weight: "",
      color: "Red",
      mDays: "40-45",
      dia: "",
      len: "8.5-10 cm",
      vigor: "",
      varities: ["High Pungency", "Width of 0.8-1 cm"],
    },
    {
      id: 2,
      name: "Kalli",
      img: "kalli",
      shape: "Cone Shaped",
      weight: "",
      color: "Black To Red",
      mDays: "65",
      dia: "1-1.2 cm",
      len: "5-6 cm",
      vigor: "",
      varities: [
        "High Pungency",
        "Dual Type and Light Wrinkled Surface",
        "Erect in Plant Canopy",
      ],
    },
    {
      id: 3,
      name: "Ranga",
      img: "ranga",
      shape: "Cone Shaped",
      weight: "",
      color: "Green & Red",
      mDays: "55",
      dia: "1 cm",
      len: "4-5 cm",
      vigor: "",
      varities: ["High Pungent Fruits", "High Yeilding", "Easy Picking"],
    },
  ],
  F1HybridSponggourd: [
    {
      id: 0,
      name: "White",
      img: "white",
      shape: "Cylindrical",
      weight: "120-140 gm",
      color: "Greenesh White",
      mDays: "40-45",
      dia: "",
      len: "30-35 cm",
      vigor: "",
      varities: [
        "Tolerance To Virus",
        "Vigorous Vines",
        "High Branching With Wider Adaptability",
        "White Seeded Fruit",
      ],
    },
    {
      id: 1,
      name: "Aman",
      img: "amanS",
      shape: "Medium Cylindrical",
      weight: "120-140 gm",
      color: "Dark Green",
      mDays: "40-45",
      dia: "",
      len: "35-40 cm",
      vigor: "",
      varities: ["High Yeild Potential and Multi Pickings"],
    },
    {
      id: 2,
      name: "Roni",
      img: "roni",
      shape: "Medium Cylindrical",
      weight: "90-100 gm",
      color: "Green",
      mDays: "45-50",
      dia: "",
      len: "16-16 cm",
      vigor: "",
      varities: ["High Yeild Potential and Multi Pickings"],
    },
  ],
  F1Hybridbringle: [
    {
      id: 0,
      name: "Ayesha",
      img: "ayesha",
      shape: "Oval Round",
      weight: "150-200 gm",
      color: "Green with White Stripes",
      mDays: "60-65",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Intermediate Resistance Fri Bacterial With Desease"],
    },
    {
      id: 1,
      name: "Aman",
      img: "aman",
      shape: "Oval Round",
      weight: "250-300 gm",
      color: "Bright Purple",
      mDays: "60-65",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Heirloom", "Full Sun Exposure"],
    },
    {
      id: 2,
      name: "WS-22",
      img: "ws22",
      shape: "Ellipsoidal",
      weight: "100-125 gm",
      color: "Pink",
      mDays: "60-70",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Suitable for Long Duration Cropping COlour Retention Capacity Even At High Temperature",
      ],
    },
    {
      id: 3,
      name: "WS-3180",
      img: "ws3180",
      shape: "Medium Long",
      weight: "100-110 gm",
      color: "Light Green",
      mDays: "60-64",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Late Seed Maturity Tolerant to Bacterial With High Yeilding"],
    },
  ],
  F1Hybridcowpea: [
    {
      id: 0,
      name: "Kranti",
      img: "kranti",
      shape: "",
      weight: "",
      color: "Light Green To Dark Green",
      mDays: "45-50",
      dia: "",
      len: "3-8 Feet",
      vigor: "",
      varities: [
        "Outdoor type, Vermicompost for Nutrients Every Week and Seaweed Once a Month For Greener Leaves and Epsom Salt for Better Blooming Once a Month",
      ],
    },
    {
      id: 1,
      name: "Saneeta",
      img: "saneeta",
      shape: "",
      weight: "2-4 Kg per Acre",
      color: "Green",
      mDays: "40-45",
      dia: "",
      len: "",
      vigor: "",
      varities: ["2-2.5 cm Deep and", "25-30 cm Length Size"],
    },
  ],
  F1HybridPalak: [
    {
      id: 0,
      name: "Bahar",
      img: "bahar",
      shape: "Leaf with oval shape",
      weight: "",
      color: "Green",
      mDays: "",
      dia: "",
      tss: "",
      len: "",
      vigor: "",
      varities: ["Small Packaging size", "By Westend Seeds", "Spinach seeds are of high-quality", "Germination rate 70-80%"],
    }
  ],
  F1HybridPoleBean: [
    {
      id: 0,
      name: "Champion",
      img: "champion",
      shape: "Trailling Type",
      weight: "5-7 gm",
      color: "Green",
      mDays: "60-62",
      dia: "",
      tss: "",
      len: "15-16 cm",
      vigor: "",
      varities: ["Very Good yeild potential"],
    }
  ],
  F1HybridMuskmillon: [
    {
      id: 0,
      name: "Marlin",
      img: "marlin",
      shape: "Oval",
      weight: "1.5-2 Kg",
      color: "Deep Salmon",
      mDays: "60-65",
      dia: "",
      tss: "13-14%",
      len: "",
      vigor: "",
      varities: ["Good Flesh Texture", "Small Seed Cavity", "Cantaloupe Type"],
    },
    {
      id: 1,
      name: "Sweet",
      img: "sweet",
      shape: "Round",
      weight: "1-1.25 Kg",
      color: "Creamy Grey",
      mDays: "55-60",
      dia: "",
      tss: "12-15%",
      len: "",
      vigor: "",
      varities: ["Good Aromatic and Deep Orange Flesh"],
    },
  ],
  F1HybridOkra: [
    {
      id: 0,
      name: "Alishba",
      img: "alishba",
      shape: "Thick Pentagonal Shape",
      weight: "",
      color: "Dark Green",
      mDays: "45-50",
      dia: "",
      len: "14-16 cm",
      vigor: "",
      varities: [
        "High Resistance of YVMV",
        "High Yeild",
        "Good for all Season Cultivation",
        "Suitable For Okra Growing Areas",
      ],
    },
    {
      id: 1,
      name: "Ankita",
      img: "ankita",
      shape: "Thick Pentagonal Shape",
      weight: "",
      color: "Dark Green",
      mDays: "45-50",
      dia: "",
      len: "12-14 cm",
      vigor: "",
      varities: [
        "There is Enhanced Production",
        "Medium Resistance of YVMV and ELCV",
      ],
    },
    {
      id: 2,
      name: "Ayra",
      img: "ayra",
      shape: "Thick Pentagonal Shape",
      weight: "",
      color: "Dark Green",
      mDays: "40-42",
      dia: "",
      len: "10-12 cm",
      vigor: "",
      varities: ["Early Concentrated Yeilding Potentiality"],
    },
    {
      id: 3,
      name: "Misslady",
      img: "misslady",
      shape: "Thick Pentagonal Shape",
      weight: "",
      color: "Green",
      mDays: "50-55",
      dia: "",
      len: "15-16 cm",
      vigor: "",
      varities: ["Better Yeild Compared to other OP Varieties"],
    },
  ],
  F1HybridOnion: [
    {
      id: 0,
      name: "WS-107",
      img: "ws107",
      shape: "Round",
      weight: "90-100 gm",
      color: "Dark Red",
      mDays: "90",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Early Variety", "Suitable For Fresh Market"],
    },
    {
      id: 1,
      name: "WS-Gouran (AFLR)",
      img: "wsGouran",
      shape: "Round",
      weight: "110-120 gm",
      color: "Eye Catch Pink",
      mDays: "105-110",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "8-9 month Good Storage Capacity",
        "High Yeild",
        "Uniform Shape",
      ],
    },
    {
      id: 2,
      name: "WS-Pune Fursungi",
      img: "wsPuneFursungi",
      shape: "Round",
      weight: "90-100 gm",
      color: "Attractive Light Red",
      mDays: "105-110",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Good Market Value", "Good Storage Capacity (Upto 6 Months)"],
    },
    {
      id: 3,
      name: "Misslady",
      img: "missladyO",
      shape: "Globe Shape",
      weight: "95-110 gm",
      color: "Attractive Dark Red",
      mDays: "90",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "3-4 month Good Storage Capacity",
        "High Yeild",
        "High Market Price",
      ],
    },
  ],
  F1HybridPeas: [
    {
      id: 0,
      name: "Gourav-10",
      img: "gourav10",
      shape: "Round",
      weight: "",
      color: "Medium Green",
      mDays: "75",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Single Bearing Type", "Tolerance to Powdery Mildew"],
    },
    {
      id: 1,
      name: "WS-11",
      img: "ws11",
      shape: "Round",
      weight: "",
      color: "Medium Green",
      mDays: "75",
      dia: "",
      len: "",
      vigor: "",
      varities: ["Single Bearing Type", "Tolerance to Powdery Mildew"],
    },
  ],
  F1HybridRadish: [
    {
      id: 0,
      name: "Glory White",
      img: "gloryWhite",
      shape: "Tapering",
      weight: "250-400 gm",
      color: "White",
      mDays: "45-50",
      dia: "",
      len: "10-12 Inches",
      vigor: "",
      varities: [
        "Roots Can Stay in the Soil for Long Period After Maturity",
        "Smooth Roots",
      ],
    },
    {
      id: 1,
      name: "Madhur-32",
      img: "madhur32",
      shape: "Tapering",
      weight: "150-200 gm",
      color: "White",
      mDays: "28-32",
      dia: "",
      len: "30-35 gm",
      vigor: "",
      varities: [
        "Roots Can Stay in the Soil for Long Period After Maturity",
        "Smooth Roots",
      ],
    },
  ],
  F1HybridSweetPaper: [
    {
      id: 0,
      name: "Anvenger",
      img: "anvenger",
      shape: "Blocky (6-7 Lobed)",
      weight: "170 gm",
      color: "Dark Green",
      mDays: "50-55",
      dia: "",
      len: "10-12 cm",
      vigor: "",
      varities: ["Plant Medium Tall", "Bushy Plants", "High Export Potential"],
    },
    {
      id: 1,
      name: "Supreme Wonder",
      img: "supremeWonder",
      shape: "Blocky (3-4 Lobed)",
      weight: "180-200 gm",
      color: "Multicolour",
      mDays: "70",
      dia: "",
      len: "",
      vigor: "",
      varities: [
        "Net Quantity will be around 25 gm",
        "Bushy Plants",
        "High Export Potential",
      ],
    },
    {
      id: 2,
      name: "WS-805",
      img: "ws805",
      shape: "2.5 cm Thickness",
      weight: "",
      color: "Light Green",
      mDays: "55",
      dia: "",
      len: "15-17 cm",
      vigor: "",
      varities: ["Semi Spreding", "Suitable for Pickle Purpose"],
    },
  ],
  F1HybridYardlongBean: [
    {
      id: 0,
      name: "WS-107",
      img: "ws107",
      shape: "",
      weight: "15-25 gm",
      color: "Light Green",
      mDays: "55-60",
      dia: "",
      len: "34-45 cm",
      vigor: "",
      varities: [
        "Hight Yeilding",
        "Tolerant to Disease",
        "4-5 Kg is Recommended Seed Rate",
      ],
    },
    {
      id: 1,
      name: "Yard Green",
      img: "yardGreen",
      shape: "",
      weight: "25-35 gm",
      color: "Medium Green",
      mDays: "Early in Maturity",
      dia: "",
      len: "34-45 cm",
      vigor: "Strong",
      varities: ["Hight Yeilding", "Tolerant to Disease", "Prolific"],
    },
  ],
};
export const keys = [
  {
    key: "F1HybridBetterGourd",
    text: "Bitter Gourd",
    img: "tinkle",
    note: "Embrace the unique flavors of bittergourd with our exceptional seed varieties, specially curated to thrive in diverse climates and soil conditions, ensuring optimal growth and yield."
  },
  {
    key: "F1HybridPumpkin",
    text: "Pumpkin",
    img: "virat",
    note: "Experience the essence of autumn with our premium pumpkin seeds, promising robust vines, vibrant colors, and sweet, succulent flesh for all your culinary and decorative needs."
  },
  {
    key: "F1HybridCucumber",
    text: "Cucumber",
    img: "kavya",
    note: "Refresh your garden and your palate with our crisp and refreshing cucumber varieties, delivering exceptional flavor, high yields, and disease resistance for a bountiful harvest."
  },
  {
    key: "F1HybridWatermelon",
    text: "Watermelon",
    img: "marshal",
    note: "Quench your thirst for sweet summer delights with our luscious watermelon seeds, bred for juicy flesh, excellent texture, and resistance to common pests and diseases."
  },
  {
    key: "F1HybridTomato",
    text: "Tomato",
    img: "yash",
    note: "Cultivate your garden with our diverse tomato seeds, carefully selected for superior flavor, disease resistance, and high yields, ensuring a bountiful harvest season after season.",
  },
  {
    key: "F1HybridCoriender",
    text: "Coriander",
    img: "super35",
    note: "Add a burst of flavor to your dishes with our aromatic coriander seeds, yielding lush foliage and abundant seeds perfect for culinary and medicinal uses."
  },
  {
    key: "F1HybridBeetrout",
    text: "Beetroot",
    img: "rudraksh",
    note: "Rediscover the earthy goodness of beetroot with our premium seed varieties, offering deep color, sweet flavor, and tender texture for salads, roasts, and more."
  },
  {
    key: "F1HybridRidgegourd",
    text: "Ridgegourd",
    img: "yashR",
    note: "Cultivate a taste of the tropics with our ridgegourd seeds, known for their vigorous vines, tender fruit, and unmatched versatility in Asian-inspired dishes."
  },
  {
    key: "F1HybridTinda",
    text: "Tinda",
    img: "navin",
    note: "Grow your own bountiful harvest of tender tinda with our premium seeds, ensuring compact plants, smooth skin, and rich flavor for delightful culinary creations."
  },
  {
    key: "F1HybridBottlegourd",
    text: "Bottle gourd",
    img: "sharad",
    note: "Elevate your garden with our bottle gourd seeds, producing long, slender fruit, mild flavor, and exceptional yields for soups, stews, and stir-fries."
  },
  {
    key: "F1HybridChilli",
    text: "Chilli",
    img: "yashC",
    note: "Ignite your taste buds with our fiery chili varieties, offering a spectrum of heat levels, vibrant colors, and robust flavor profiles to spice up any dish."
  },
  {
    key: "F1HybridSponggourd",
    text: "Spong gourd",
    img: "roni",
    note: "Discover the versatility of sponge gourd with our premium seeds, delivering vigorous vines, tender fruit, and spongy texture perfect for soups and curries."
  },
  {
    key: "F1Hybridbringle",
    text: "Brinjal",
    img: "aman",
    note: "Indulge in the rich flavors of brinjal with our diverse seed collection, boasting glossy fruit, firm texture, and excellent disease resistance for a successful harvest."
  },
  {
    key: "F1Hybridcowpea",
    text: "Cowpea",
    img: "saneeta",
    note: "Sow the seeds of sustainability with our cowpea varieties, prized for their drought tolerance, high protein content, and nitrogen-fixing properties for healthier soils."
  },
  {
    key: "F1HybridFrenchBean",
    text: "French Bean",
    img: "malti",
    note: "Reach new heights in your garden with our pole bean seeds, climbing vines, prolific yields, and tender pods that are perfect for fresh eating and canning."
  },
  {
    key: "F1HybridKnolKhul",
    text: "Knol Khol",
    img: "wsHeena",
    note: "Celebrate diversity in your garden with our knol khol seeds, offering crisp, mild-flavored bulbs, and nutritious greens, perfect for salads and pickling."
  },
  {
    key: "F1HybridMuskmillon",
    text: "Muskmelon",
    img: "sweet",
    note: "Savor the sweetness of summer with our muskmelon seeds, producing fragrant fruit, juicy flesh, and high sugar content for irresistible summertime treats."
  },
  {
    key: "F1HybridPalak",
    text: "Palak",
    img: "bahar",
    note: "Nourish your garden and your body with our palak seeds, yielding tender leaves, rich flavor, and abundant vitamins and minerals for healthy, homegrown meals."
  },
  {
    key: "F1HybridPoleBean",
    text: "Pole Bean",
    img: "champion",
    note: "Reach new heights in your garden with our pole bean seeds, climbing vines, prolific yields, and tender pods that are perfect for fresh eating and canning."
  },
  {
    key: "F1HybridOkra",
    text: "Okra",
    img: "alishba",
    note: "Experience the delight of homegrown bhendi with our premium seeds, boasting sturdy plants, tender pods, and excellent disease resistance for a productive harvest."
  },
  {
    key: "F1HybridOnion",
    text: "Onion",
    img: "ws107",
    note: "Grow your own flavor base with our onion seeds, producing crisp bulbs, rich flavor, and long storage life for culinary versatility and year-round enjoyment."
  },
  {
    key: "F1HybridPeas",
    text: "Peas",
    img: "gourav10",
    note: "Harvest the taste of spring with our pea seeds, offering sweet, tender pods, and high yields for fresh eating, freezing, and garden-fresh meals."
  },
  {
    key: "F1HybridRadish",
    text: "Radish",
    img: "gloryWhite",
    note: "Add a pop of color and crunch to your garden with our radish seeds, producing vibrant roots, crisp texture, and zesty flavor for salads, garnishes, and snacks."
  },
  {
    key: "F1HybridSweetPaper",
    text: "Sweet Pepper",
    img: "supremeWonder",
    note: "Satisfy your craving for sweetness and crunch with our sweet pepper seeds, delivering colorful fruit, crisp texture, and rich flavor for fresh eating and cooking."
  },
  {
    key: "F1HybridYardlongBean",
    text: "Yardlong Bean",
    img: "ws107B",
    note: "Embark on a culinary adventure with our yardlong bean seeds, featuring long, slender pods, delicate flavor, and high nutritional value for stir-fries and salads."
  },
];
export const aboutData = [
  "At Westend Seeds, quality assurance is paramount. We implement stringent quality control measures at every stage of the seed production process, from sourcing and breeding to packaging and distribution.",
  "By upholding the highest standards of quality, we ensure that farmers receive seeds that consistently perform at their best, ultimately contributing to enhanced productivity and profitability.",
  "Our overarching goal is not only to provide seeds but to empower farmers with the tools and support they need to thrive. Through our unwavering dedication to research, innovation, and quality, we are committed to driving positive change in agriculture, improving the lives of farmers, and fostering sustainable growth in communities around the world.",
  "Through extensive field trials conducted across various regions, we rigorously test and refine our seeds to guarantee superior quality and performance. ",
  "These trials not only validate the effectiveness of our products but also allow us to gather invaluable feedback from farmers, enabling us to continuously improve and adapt to their evolving needs.",
  "The testimonials from farmers serve as a testament to the efficacy of our seeds and the positive impact they have on their livelihoods.",
  "We take great pride in the success stories shared by farmers who have experienced increased yields, improved crop resilience, and ultimately, higher incomes as a result of using our seeds.",
]
