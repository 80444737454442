/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import SpecifiedCrops from "../views/SpecifiedCrops";
import AboutUs from "../views/About"

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "splashScreen",
    component: () => import("./../views/SplashScreen.vue"),
  },
  {
    path: "/crop",
    name: "crop",
    component: () => import("./../layouts/ActiveLayout.vue"),
    children: [
      {
        path: "details",
        name: "details",
        component: HomeView,
      },
      {
        path: "specific-crops",
        name: "specific-crops",
        component: SpecifiedCrops,
      },
      {
        path: "about-us",
        name: "about-us",
        component: AboutUs,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
