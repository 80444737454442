<template>
  <div class="wrapper">
    <div class="content-for-mob my-5">
        <div class="why-chhose-us" style="color: #006064">
            <div align="center" class="text-h5">
            <h2>Why Choose Us...? </h2>
            </div>
            <div class="chooseus-icons mt-6" align="center">
                <div>
                    <span class="slide-right mdi mdi-search-web text-h3" style="color: #333"></span>
                    <div class="slide-left font-weight-medium icon-text text-h5">Extensive R&D</div>
                </div>
                <div class="mt-3">
                    <span class="slide-right mdi mdi-lightbulb-on text-h3" style="color: #333"></span>
                    <div class="slide-left font-weight-medium icon-text text-h5">Innovation & Technologies</div>
                </div>
                <div class="mt-3">
                    <span class="slide-right mdi mdi-quality-high text-h3" style="color: #333"></span>
                    <div class="slide-left font-weight-medium icon-text text-h5">Quality Production</div>
                </div>
                <div class="mt-3">
                    <span class="slide-right mdi mdi-washing-machine text-h3" style="color: #333"></span>
                    <div class="slide-left font-weight-medium icon-text text-h5">State of Art Processing</div>
                </div>
                <div class="mt-3">
                    <span class="slide-right mdi mdi-map-marker-multiple text-h3" style="color: #333"></span>
                    <div class="slide-left font-weight-medium icon-text text-h5">Marketing at Farmer's Door step</div>
                </div>
            </div>
            <div class="mx-4 mb-12" style="color: #333">
                <div v-for="(data, i) in aboutDataArr" :key="i">
                <div class="about-points mb-4">
                <span class="mdi mdi-checkbox-blank-circle mx-2"></span>
                <span style="margin-top: -1px; font-size: 18px">{{data}}</span>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-for-pc">
    </div>
  </div>
</template>
<style scoped>
.chooseus-icons {
  display: flex;
  flex-direction: column;
}
.slide-right {
  animation: 1s slide-right;
  margin-bottom: 2rem;
}
.about-points {
    display: flex;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
.slide-left {
  animation: 1s slide-left;
  margin-bottom: 2rem;
}
@keyframes slide-left {
  from {
    margin-right: -100%;
  }
  to {
    margin-left: 0%;
  }
}
</style>
<script>
import { aboutData } from '@/utils/crop-data';
// @ is an alias to /src
// import router from "@/router";
export default {
  name: "AboutUs",
  data: () => ({
    aboutDataArr: []
  }),
  created() {
    this.aboutDataArr = aboutData
  },
};
</script>
