<template>
  <div class="wrapper">
    <div v-if="cropList" class="crop-list">
      <div v-for="(crop, i) in cropList" :key="i">
        <v-card
          max-width="333"
          min-height="620"
          :variant="crop.id"
          class="mx-6 my-6 card"
        >
          <v-card-item>
            <div>
              <img class="crop-img" :src="getImg(crop.img)" />
              <div class="text-primary crop-name">
                {{ crop.name }}
              </div>
              <div class="font-weight-bold my-2">
                {{ crop.varities.join(", ") }}
              </div>
              <div class="crop-detail-wrapper">
                <span v-if="crop?.shape"
                  >Fruit Shape: <b>{{ crop?.shape }}</b></span
                >
                <span v-if="crop?.weight"
                  >Fruit Weight: <b>{{ crop?.weight }}</b></span
                >
                <span v-if="crop?.color"
                  >Fruit Color: <b>{{ crop?.color }}</b></span
                >
                <span v-if="crop?.fruitFlesh"
                  >Fruit Flesh: <b>{{ crop?.fruitFlesh }}</b></span
                >
                <span v-if="crop?.type"
                  >Fruit Flesh: <b>{{ crop?.type }}</b></span
                >
                <span v-if="crop?.tss"
                  >TSS: <b>{{ crop?.tss }}</b></span
                >
                <span v-if="crop?.mDays"
                  >Maturity Days: <b>{{ crop?.mDays }}</b></span
                >
                <span v-if="crop?.dia"
                  >Diameter: <b>{{ crop?.dia }}</b></span
                >
                <span v-if="crop?.len"
                  >Length: <b>{{ crop?.len }}</b></span
                >
              </div>
            </div>
          </v-card-item>
        </v-card>
      </div>
    </div>
    <div v-else class="future-note">Crops info will display soon...</div>
  </div>
</template>
<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  margin-top: 2rem;
  width: 85vw;
  grid-gap: 1rem;
}
.crop-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.card {
  padding: 5px;
  border-radius: 5px;
}
.card:hover {
  border: 5px solid #006064;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
.crop-img {
  width: 100%;
  height: 320px;
  border-radius: 5px;
  border: 1px solid #333;
}
.crop-name {
  font-size: 2rem;
  font-weight: 600;
  color: #006064;
}
.crop-detail-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.future-note {
  font-size: 1rem;
  color: #004d40;
  font-family: "Courier";
  white-space: nowrap;
  overflow: hidden;
  font-weight: 900;
  width: 100%;
  animation: type 4s steps(60, end);
}
@keyframes type {
  from {
    width: 0;
  }
}
</style>
<script>
// @ is an alias to /src
import { crops } from "../utils/crop-data";
import router from "@/router";
export default {
  name: "SpecifiedCrops",
  data: () => ({
    cropKey: "",
    cropList: [],
  }),
  methods: {
    mapCrops() {
      this.cropList = crops[this.cropKey];
    },
    getImg(val) {
      return require(`@/assets/crops-imgs/${val}.png`);
    },
  },
  created() {
    if (this.$route.query?.crop) {
      this.cropKey = this.$route.query?.crop;
      this.mapCrops();
    } else {
      router.push({ name: "details" });
    }
  },
};
</script>
